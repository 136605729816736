// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--1ZwYq";
export var btnBlock = "banner-module--btnBlock--US-Sd";
export var container = "banner-module--container--IX9yb";
export var hideMobBlock = "banner-module--hideMobBlock---duWJ";
export var img = "banner-module--img--6TsrY";
export var mobBtn = "banner-module--mobBtn--G++d6";
export var pkwBonus = "banner-module--pkwBonus--kYp8J";
export var secondContainer = "banner-module--secondContainer--w5v-S";
export var subTextFriday = "banner-module--subTextFriday--wxEdl";
export var text = "banner-module--text--qsJ3G";